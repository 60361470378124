import { useState, useEffect } from 'react';
import { IUseIntersection } from './useIntersection.types';

const useIntersection = ({
  element, // ref to element
  rootMargin = '0px', // offset for when the element is visible
  useOnce = true, // when false this will continue to observe until element is unmounted
}: IUseIntersection): boolean => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const node = element.current;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (useOnce && entry.isIntersecting) {
          observer.unobserve(node);
        }
        setIsVisible(entry.isIntersecting);
      },
      { rootMargin },
    );

    node && observer.observe(node);

    return () => {
      return observer.unobserve(node);
    };
  }, [element, rootMargin, useOnce]);

  return isVisible;
};

export default useIntersection;
