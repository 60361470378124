import React from 'react';
import { withRouter } from 'next/router';
import App from 'next/app';
import type { NextWebVitalsMetric } from 'next/app';
import { ThemeProvider } from '@mui/system';

import '../scss/vendor/_reset.scss';
import '../scss/app.scss';
import '@contentful/live-preview/style.css';

import { ConsentManager } from 'src/containers/ConsentManager';
import { init as initSentry } from 'src/lib/sentry';
import { trackEvent } from 'public/assets/js/analytics';
import { useCookies } from 'src/hooks';
import theme from 'src/components-v2/theme';
import { AnalyticsProvider } from 'src/contexts';

initSentry();

/**
 * Report Web Vitals
 * NextJS abstraction uses web-vitals library under the hood
 * https://nextjs.org/docs/advanced-features/measuring-performance#build-your-own
 * https://github.com/GoogleChrome/web-vitals
 */
export function reportWebVitals({
  id,
  name,
  value,
}: NextWebVitalsMetric): void {
  const coreWebVitals = ['LCP', 'FID', 'CLS'];
  if (coreWebVitals.includes(name)) {
    trackEvent({
      event: `REPORT_WEB_VITALS_METRIC_${name}`,
      payload: {
        metric_id: id,
        metric_value: value,
        nonInteraction: 1,
      },
    });
  }
}

const PlaidApp = (props) => {
  const { Component, pageProps, router, err } = props;

  useCookies();

  if (Component.layout) {
    //  for Docs only (only Docs have layout)
    const Layout = Component.layout;
    return (
      <>
        <AnalyticsProvider>
          <Layout {...props} pathname={router.pathname} />
          <ConsentManager />
        </AnalyticsProvider>
      </>
    );
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Component {...pageProps} err={err} />
      </ThemeProvider>
    </>
  );
};

// monkeypatch for webpack HMR issue
// https://github.com/zeit/next-plugins/issues/282#issuecomment-594088880
// https://github.com/sheerun/extracted-loader/issues/11
if (module.hot) {
  module.hot.addStatusHandler((status) => {
    if (typeof window !== 'undefined' && status === 'ready') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.__webpack_reload_css__ = true;
    }
  });
}

PlaidApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, pageProps: { ...appProps.pageProps } };
};

PlaidApp.displayName = 'App';

export default withRouter(PlaidApp);
