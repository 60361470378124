import { NAVIGATION_EVENTS, HOMEPAGE_EVENTS } from 'src/lib/trackingEvents';

export enum ExperimentName {
  TEST_EXPERIMENT = 'plaid_dot_com_test_experiment',
  HYBRID_SIGNUP_FLOWS_EXPERIMENT = 'hybrid_sign-up_flows',
  DSR_FORM_V2 = 'dsr_form_v2',
  LOGGED_IN_DOCS = 'logged_in_docs',
}

export enum TestExperimentVariant {
  CONTROL = 'control',
  VARIANT = 'variant',
}

export enum DSRFormV2ExperimentVariant {
  CONTROL = 'control',
  VARIANT = 'variant',
}

export const OPTIMIZELY_EXPERIMENTS_EVENTS = [
  'CONTACT_FORM_SUBMIT',
];
